import styled from "styled-components";
import { Design, Code } from "../assets/svgs/AllSvgs";
import HomeButton from "../subcomponents/HomeButton";
import HomeButtonDarkMode from "../subcomponents/HomeButtonDarkMode";
import ParticlesBG from "../components/ParticlesBG";
import ParticlesBGDarkMode from "../components/ParticlesBGDarkMode";
import GlobalStyle from "../styles/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import { Helmet } from 'react-helmet-async';


const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 479px) {
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 5rem;
}
@media (min-width: 480px) and (max-width: 768px) {
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 4rem;
}
@media (min-width: 769px) and (max-width: 1023px) {
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 0rem;
}
`;

const Main = styled.div`
  border: 2px solid ${(props) => props.theme.text};
  color: ${(props) => props.theme.text};
  background-color: transparent;
  padding: 2rem;
  width: 30vw;
  height: 60vh;
  z-index: 3;
  overflow-y: auto;
  line-height: 1.5;
  cursor: pointer;
  font-family: "Ubuntu Mono", monospace;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
    transition: all 0.9s ease;
  }

  @media (max-width: 479px) {
    width: 80vw;
    height: auto;
    margin: 2rem auto;
}
@media (min-width: 480px) and (max-width: 768px) {
  width: 80vw;
  height: auto;
  margin: 1rem auto;
}
@media (min-width: 769px) and (max-width: 1024px) {
  width: 80vw;
  height: auto;
  margin: 0rem auto 1rem auto;
}
`;

const Title = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vw);
  fill: ${(props) => props.theme.text};

  ${Main}:hover & {
    & > * {
      fill: ${(props) => props.theme.body};
      transition: all 0.9s ease;
    }
  }

  & > *:first-child {
    margin-right: 1rem;
  }
`;

const Description = styled.div`
  color: ${(props) => props.theme.text};
  font-size: calc(0.6em + 1vw);
  padding: 0.5rem 0;

  strong {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  ul,
  p {
    margin-left: 2rem;
  }

  ${Main}:hover & {
    color: ${(props) => props.theme.body};
  }
`;

const TitleSEO = styled.h1`
  color: ${props => props.theme.body};
  position: absolute;
  bottom:0;
  right:0;
  font-size: 0.1rem;
  cursor: default;
`

const BottomBar = styled.div`
  margin: 1rem auto;
  width: 400px;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
`

const Certification = styled.img`
  position: relative;
  height: auto;
  max-width: 100px;
  padding: 1rem;
`

const Skills = () => {
  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <>
    <Helmet>
      <title>Skills</title>
      <meta name="description" content="William Stein - Skills: Graphic Designer and Web Developer"/>
      <link rel="canonical" href="/skills" />
    </Helmet>
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      {theme === "light" ? <HomeButton /> : <HomeButtonDarkMode />}
      <Box>
        {theme === "light" ? <ParticlesBG /> : <ParticlesBGDarkMode />}
        <TitleSEO>Skills: Graphic Designer & Web Developer</TitleSEO>
        <Main>
          <Title>
            <Design style={{ fill: "#e93227" }} width={40} height={40} />{" "}
            Digital Designer
          </Title>
          <Description style={{ color: "inherit" }}>
            I like to draw handmade illustrations and fill them with life. But what I like above all is to create a physical object which will be seen or manipulated by people.
          </Description>
          <Description style={{ color: "inherit" }}>
            <strong>I like to design</strong>
            <ul>
              <li>Websites</li>
              <li>Illustrations</li>
              <li>Animations</li>
            </ul>
          </Description>
          <Description style={{ color: "inherit" }}>
            <strong>I use tools like</strong>
            <ul>
              <li>Figma</li>
              <li>Illustrator</li>
              <li>Photoshop</li>
              <li>InDesign</li>
              <li>After Effects</li>
              <li>Animate</li>
            </ul>
          </Description>
        </Main>

        <Main>
          <Title>
            <Code style={{ fill: "#e93227" }} width={40} height={40} /> Web
            Developer
          </Title>
          <Description style={{ color: "inherit" }}>
            I like to do all kind of websites: creative, showcase, corporate, blog ... I've learned and grown up a lot in this domain for several years now.
          </Description>
          <Description style={{ color: "inherit" }}>
            <strong>Skills</strong>
            <p> Html, Css, Js, React, Jsx, Sass, Bootstrap, Php </p>
          </Description>
          <Description style={{ color: "inherit" }}>
            <strong>Tools</strong>
            <p>Visual Studio Code, Wordpress, Webflow, Lottie, Wamp & Github </p>
          </Description>
        </Main>
      </Box>
      <BottomBar>
      {theme === "light" ?
        <Certification src="https://res.cloudinary.com/wstein88/image/upload/v1647519549/iad-certification_g7hfqi.png" Title="IAD Certification" alt="IAD Logo - black">
        </Certification> :
        <Certification src="https://res.cloudinary.com/wstein88/image/upload/v1647519549/iad-certification-darkMode_ewxufm.png" Title="IAD Certification" alt="IAD Logo - white">
        </Certification>}
        {theme === "light" ?
        <Certification src="https://res.cloudinary.com/wstein88/image/upload/v1647522388/iab-digital-marketing-google-certification_ap2fvd.png" Title="IAB Digital Martketing Google Certification" alt="IAB Logo - black">
        </Certification> : 
        <Certification src="https://res.cloudinary.com/wstein88/image/upload/v1647522388/iab-digital-marketing-google-certification-DarkMode_lxpv4c.png" Title="IAB Digital Martketing Google Certification" alt="IAB Logo - white">
        </Certification>}
      </BottomBar>
    </ThemeProvider>
    </>
  );
};

export default Skills;
