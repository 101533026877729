import {useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import styled from 'styled-components';
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import GlobalStyle from "../styles/globalStyle";
import {useParams} from 'react-router';
import {blogList} from '../assets/datas/BlogDatas';
import EmptySearch from "../subcomponents/EmptySearch";
import GoBackButton from "../subcomponents/GoBack";
import GoBackButtonDarkMode from "../subcomponents/GoBackDarkMode";
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet-async';

const SuperContainer = styled.div`
background: ${(props) => props.theme.body};
width: 100vw;
height: 100vh;
`

const MainContainer = styled.div`
  position: relative;
  max-width: 900px;
  margin: 6rem auto;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: ${(props) => props.theme.body};
  
  color: ${(props) => props.theme.text};
`

const Header = styled.header`
  text-align: center;
`

const Date = styled.p`
  font-size: 0.8rem;
  font-weight: 500;
`

const Title = styled.h1`
  margin: 0;
  padding: 3rem 1.5rem;
`

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: 0 1.5rem;
  margin: 0;
`

const Description = styled.div`
width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem 3rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.5em;
  h3 {
    width: 100%;
    text-align: center;
    text-decoration: underline;
    padding-top: 1.5rem;
    
  }
  p{
    padding: 1rem 0;
  }
  ol{
    padding-top: 1.5rem;
  }
  ul{
    padding-top: 1.5rem;
  }
  a {
    ${(props) => props.theme.text};
  }
`

const SubTags = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
  font-style: bold;
  margin: 0;
  padding: 0 1.5rem 3rem 1.5rem;
  font-style: italic;
p {
  padding-right: 1rem;
}
`;

const BlogCardPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    let blog = blogList.find((blog) => blog.id === parseInt(id));
    if (blog) {
      setBlog(blog);
    }
  }, [id]);

  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      {theme === "light" ? <NavLink to="/blog/"><GoBackButton /></NavLink> : <NavLink to="/blog/" ><GoBackButtonDarkMode/></NavLink>}
      <SuperContainer>


        {
            blog ? <>
            <Helmet>
              <title>{blog.name}</title>
              <meta name="description" content={blog.meta}/>
              <link rel="canonical" href={`/blog/${blog.id}/${blog.url}`} />
            </Helmet>
            <MainContainer>
                <Header>
                    <Date>Published {blog.date}</Date>
                    <Title>{blog.name}</Title>
                </Header>
                {theme === "light" ? <Image src={blog.imgSrc[1]} alt={blog.alt} title={blog.title}/> : <Image src={blog.imgSrc[0]} alt={blog.alt} title={blog.title}/>}
                <SubTags>
                        {blog.subTags.map((subtag, id)=>(
                        <p key={id}>#{subtag}</p>
                        )
                        )}
                    </SubTags>
                <Description>{parse(blog.description)}</Description>
            </MainContainer> 
            </>
            : (
            <EmptySearch />
            )}
            </SuperContainer>
    </ThemeProvider>
  );
};

export default BlogCardPage