import Particles from "react-tsparticles";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const ParticlesBG = () => {
  const particlesInit = () => {
    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = () => {};
  return (
    <Container>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#ffffff",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "push",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 200,
                duration: 2,
                opacity: 0.8,
                size: 30,
              },
              push: {
                quantity: 10,
              },
              repulse: {
                distance: 200,
                duration: 0.4,
              },
            },
          },
          particles: {
            color: {
              value: "#000000",
            },
            links: {
              color: "#ffffff",
              distance: 150,
              enable: false,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: false,
            },
            move: {
              direction: "right",
              enable: true,
              outMode: "out",
              random: false,
              speed: 5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                area: 800,
              },
              value: 10,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
              stroke: {
                width: 0,
                color: "#000000",
              },
            },
            size: {
              random: true,
              value: 5,
            },
          },
          detectRetina: true,

          emitters: {
            position: {
              y: 55,
              x: -30,
            },
            rate: {
              delay: 12,
              quantity: 1,
            },
            size: {
              width: 0,
              height: 0,
            },
            particles: {
              shape: {
                type: "images",
                options: {
                  images: [
                    {
                      src: "https://res.cloudinary.com/wstein88/image/upload/v1646349158/birdy_nhmq2z.svg",
                    },
                    {
                      src: "https://res.cloudinary.com/wstein88/image/upload/v1646056344/flyingMonster_uiibmk.svg",
                    },
                    {
                      src: "https://res.cloudinary.com/wstein88/image/upload/v1646056596/slugMonster_ewfh4b.svg",
                    },
                    {
                      src: "https://res.cloudinary.com/wstein88/image/upload/v1646057224/wormMonster_iajixe.svg",
                    },
                  ],
                },
              },
              size: {
                value: 100,
              },
              move: {
                speed: 5,
                outModes: {
                  default: "destroy",
                  left: "none",
                },
                straight: true,
              },
              zIndex: {
                value: 0,
              },
              rotate: {
                value: {
                  min: 0,
                  max: 360,
                },
                animation: {
                  enable: true,
                  speed: 10,
                  sync: true,
                },
              },
            },
          },
        }}
      />
    </Container>
  );
};

export default ParticlesBG;
