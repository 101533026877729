import React from "react";
import { motion } from "framer-motion";
import "../styles/App.css";

const blackBox = {
  initial: {
    height: "100vh",
    bottom: 0,
    backgroundColor: "black",
  },
  animate: {
    height: 0,
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
};

const InitialTransition = () => {
  return (
    <motion.div
      className="transition"
      initial="initial"
      animate="animate"
      variants={blackBox}
      onAnimationStart={() => document.body.classList.add("overflow-hidden")}
      onAnimationComplete={() =>
        document.body.classList.remove("overflow-hidden")
      }
    ></motion.div>
  );
};

export default InitialTransition;
