import {useEffect, useState} from 'react';
import {Works} from '../assets/datas/WorkDatas';
import styled from 'styled-components';
import WorkCard from '../components/WorkCard';
//import LogoComponent from '../subcomponents/LogoComponent';
import SocialIcons from '../subcomponents/SocialIcons';
import SocialIconsDarkMode from '../subcomponents/SocialIconsDarkMode';
import HomeButton from '../subcomponents/HomeButton';
import Filter from '../components/Filter';
import Toggle from '../components/SwitchButton';
import ToggleDarkMode from '../components/SwitchButtonDarkMode';
import {lightTheme, darkTheme} from '../components/Themes';
import  {useDarkMode} from "../components/useDarkMode";
import {ThemeProvider} from 'styled-components';
import HomeButtonDarkMode from '../subcomponents/HomeButtonDarkMode';
import GlobalStyle from '../styles/globalStyle';
import { Helmet } from 'react-helmet-async';


const MainContainer = styled.div`
    position: relative;
    width: 100vw;
    height: auto;
    background: ${props => props.theme.body};
    overflow: hidden;
`

const Grid = styled.div`
    margin: auto;
    padding-top: 50px;
    width: 70%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    grid-gap: 0px;
    @media (max-width: 767px) {
        width: 100%;
    }

`

const Tags = styled.div `
    text-align: center;
    margin: 7rem auto 0 auto; 

`

const TitleSEO = styled.h1`
  color: ${props => props.theme.body};
  position: absolute;
  bottom:0;
  right:0;
  font-size: 0.1rem;
  cursor: default;
`

const Work = () => {
    const [tag, setTag] = useState('all');
    const [filteredImages, setFilteredImages] = useState([]);

    useEffect(()=>{
        tag === 'all' ? setFilteredImages(Works) : setFilteredImages(Works.filter( work => work.tags === tag))
    },[tag])

    const [theme, themeToggler] = useDarkMode();

    const themeMode = theme === 'light' ? lightTheme : darkTheme;

    return (
        <>
        <Helmet>
          <title>Work</title>
          <meta name="description" content="William Stein - Works: Illustrations, branding, events and web development"/>
          <link rel="canonical" href="/work" />
        </Helmet>
        <ThemeProvider theme={themeMode} >
            <GlobalStyle/>
            {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
            {theme==='light' ? <HomeButton /> : <HomeButtonDarkMode/>}
            {theme==='light' ? <SocialIcons /> : <SocialIconsDarkMode/>}
        <MainContainer>
        <TitleSEO>Works: illustrations, branding, events and web development</TitleSEO>
        <Tags>
            <Filter name="all" handleSetTag={setTag} tagActive={ tag === 'all' ? true : false}/>
            <Filter name="illustrations" handleSetTag={setTag} tagActive={ tag === 'illustrations' ? true : false}/>
            <Filter name="branding" handleSetTag={setTag} tagActive={ tag === 'branding' ? true : false}/>
            <Filter name="events" handleSetTag={setTag} tagActive={ tag === 'events' ? true : false}/>
            <Filter name="web" handleSetTag={setTag} tagActive={ tag === 'web' ? true : false}/>
        </Tags>
        <Grid>
            {
                filteredImages.map(work=> {
                    return <WorkCard key={work.id} work={work} to={work.url}/>
                })
            }
        </Grid>
        </MainContainer>
        </ThemeProvider>
        </>
    )
}

export default Work;