import styled from 'styled-components';
import {ThemeProvider} from 'styled-components';
import GlobalStyle from '../styles/globalStyle';
import { NavLink } from "react-router-dom";
import HomeButton from "../subcomponents/HomeButton";
import HomeButtonDarkMode from "../subcomponents/HomeButtonDarkMode";
import Toggle from '../components/SwitchButton';
import ToggleDarkMode from '../components/SwitchButtonDarkMode';
import {lightTheme, darkTheme} from '../components/Themes';
import  {useDarkMode} from "../components/useDarkMode";
import LrAnim from '../subcomponents/LrAnim';
import LrAnimDarkMode from '../subcomponents/LrAnimDarkMode';
import ParticlesBG from "../components/ParticlesBG";
import ParticlesBGDarkMode from "../components/ParticlesBGDarkMode";
import PlayAnim from '../subcomponents/PlayAnim';
import PlayAnimDarkMode from '../subcomponents/PlayAnimDarkMode';
import Headband from '../subcomponents/Headband';
import HeadbandDarkMode from '../subcomponents/HeadbandDarkMode';

import { Helmet } from 'react-helmet-async';



const MainContainer = styled.div`
    width: 100vw;
    height: auto;
    background: ${props => props.theme.body};
    overflow: hidden;
`
const Container = styled.div`
  position: relative;
  margin: 8rem auto;
  width: 100%;
  height: 60%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
`

const Box = styled.div`
  position: relative;
  width: 50vw;
  height: auto;
  display: flex;
  margin: 1rem auto;
  padding: 1rem;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid ${props => props.theme.text};
  
    @media (max-width: 479px) {
      flex-flow: column wrap;
      width: 90%;
  }
    @media (min-width: 400px) and (max-width: 768px) {
      flex-flow: column wrap;
      width: 90%;
    }
`
const Image = styled.div`
padding-left: 1rem;
`
const SubBox = styled.div`
  display: flex;
  justifify-content: center;
  flex-flow: column wrap;
  align-items: center;
  padding: 3rem;
`
const Title = styled.h2`
color: ${props => props.theme.text};
font-size: 2rem;
`
const Description = styled.p`
color: ${props => props.theme.text};
margin: 1rem auto;
text-align: center;
a {
  color: #e93227;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
`

const Link = styled.div`
  height: auto;
  width: 180px;
  padding: 0.5rem;
  text-align: center;
  margin-top: 0.8rem;
  border: 2px solid ${props => props.theme.text};
  
  color: ${props => props.theme.body};
  box-shadow: inset 0 0 0 0 #e93227;
  transition: .3s ease-in-out;
  cursor: pointer;
  &:hover{
    box-shadow: inset 180px 0 0 0 #e93227;
    color:  ${props => props.theme.body};
  }
  a {
    color:  ${props => props.theme.text};
    text-decoration: none;
    font-weight: 500;
    font-size: 1.2rem;
  }
`
const TitleSEO = styled.h1`
  color: ${props => props.theme.body};
  position: absolute;
  bottom:0;
  right:0;
  cursor: default;
  font-size: 0.1rem;
`

const Projects = () => {
  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === 'light' ? lightTheme : darkTheme;
  return (
    <>
    <Helmet>
      <title>Projects</title>
      <meta name="description" content="William Stein - Projects: Lovve Reiniger illustrations, 1day1cover blog and DAMMM videos"/>
      <link rel="canonical" href="/projects" />
    </Helmet>
    <ThemeProvider theme={themeMode} >
            <GlobalStyle />
            {theme === "light" ? <HomeButton /> : <HomeButtonDarkMode/>}
            {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      
      
      <MainContainer>
      {theme === "light" ? <ParticlesBG /> : <ParticlesBGDarkMode />}
      
        <Container>
        <TitleSEO>Projects: Lovve Reiniger, 1day1cover and DAMMM</TitleSEO>
          <Box>
            <Image>
              {theme === "light" ? <LrAnim /> : <LrAnimDarkMode/>}
            </Image>
            <SubBox>
              <Title>
                Lovve Reiniger
              </Title>
              <Description>
              Creative website for my illustrations based on silhouettes animation / silhouettes art. Learn more about this topic: <NavLink target="_blank" to="/blog/3/animation-de-silhouettes" >L'animation de silhouettes</NavLink> (fr).<br/>
              I'm now focus on web development, so I slowed this project down.
              </Description>
              <Link><a target="_blank" rel="noreferrer" href="https://www.lovvereiniger.com">lovvereiniger.com</a></Link>
            </SubBox>
          </Box>
          <Box>
            
            <SubBox>
              <Title>
                1Day1Cover
              </Title>
              <Description>
              This is a EDM (Electronic Dance Music) Wordpress Blog where I share everyday a EP/album cover with a spotify link. My goal is to put some light on the graphic designers who did the artworks.
              </Description>
              <Link><a target="_blank" rel="noreferrer" href="https://www.1day1cover.com">1day1cover.com</a></Link>
            </SubBox>
            <Image>
              {theme === "light" ? <PlayAnim /> : <PlayAnimDarkMode/>}
            </Image>
          </Box>
          <Box>
            <Image>
              {theme === "light" ? <Headband /> : <HeadbandDarkMode/>}
            </Image>
            <SubBox>
              <Title>
                D.A.M.M.M.
              </Title>
              <Description>
              Single page website for chill montages mixing asian movies and minimal music. I like to share gold nuggets in cinema (100% no spoil) in a contemplative way. If you're also a cinephile, check my top 10 <NavLink target="_blank" to="/blog/2/cinema-suggestions-top10" >Top 10</NavLink> and <a href="mailto:info@wstein.be">let's have a chat</a> about it.
              </Description>
              <Link><a target="_blank" rel="noreferrer" href="https://www.dammm.be">dammm.be</a></Link>
            </SubBox>
          </Box>
        </Container>
      </MainContainer>
    </ThemeProvider>
    </>
  );
};

export default Projects;
