export const Works = [
    {
        id:1,
        name:"The Killer",
        description:'"Let not light see my black and deep desires" - Shakespeare',
        tags:"illustrations",
        url:"/work/the-killer",
        meta:"William Stein - Illustration - Lovve Reiniger - The Killer",
        alt:"Lovve Reiniger - The Killer",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/killer-min_utzfrx.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/killer_vkbz44.jpg"
    },
    {
        id:2,
        name:"The Trap",
        description:"When I was a kid, I usually did nightmares. Always the same pattern: feeling something scary coming for me while I was trapped.",
        tags:"illustrations",
        url:"/work/the-trap",
        meta:"William Stein - Illustration - Lovve Reiniger - The Trap",
        alt:"Lovve Reiniger - The Trap",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/trap-min_t0l7n1.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/trap_g9fahd.jpg"
        
    },
    {
        id:3,
        name:"Me, Myself and I",
        description:"Me, Myself and I, that's all I got at the end of the day.",
        tags:"illustrations",
        url:"/work/me-myself-and-i",
        meta:"William Stein - Illustration - Lovve Reiniger - Me, Myself and I",
        alt:"Lovve Reiniger - Me, Myself and I",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647013650/me-myself-and-i-min_rxufnd.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/me-myself-and-i_eu3xzq.jpg"
    },
    {
        id:4,
        name:"Natural Selection",
        description:'"If you want to achieve your goals, do not focus on them" - Reggie Rivers',
        tags:"illustrations",
        url:"/work/natural-selection",
        meta:"William Stein - Illustration - Lovve Reiniger - Natural Selection",
        alt:"Lovve Reiniger - Natural Selection",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/natural-selection-min_n95225.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/natural-selection_kajuw2.jpg"
       
    },{
        id:5,
        name:"The Mirror",
        description:'"We must never be afraid to be a sign of contradiction for the world" - Mother Teresa',
        tags:"illustrations",
        url:"/work/the-mirror",
        meta:"William Stein - Illustration - Lovve Reiniger - The Mirror",
        alt:"Lovve Reiniger - The Mirror",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/mirror-min_huy7dt.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/mirror_rf2ntn.jpg"
        
    },
    {
        id:6,
        name:"Duality",
        description:'"Duality is always secretly unity" - Alan Watts',
        tags:"illustrations",
        url:"/work/duality",
        meta:"William Stein - Illustration - Lovve Reiniger - Duality",
        alt:"Lovve Reiniger - Duality",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647012152/duality-min_saiduw.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/duality_y5i8gj.jpg"
        
    },
    {
        id:7,
        name:"Routine Life",
        description:'"So, after all, we are but puppets, creatures of our fate, not commanding it but being molded by it" - Eleanor Roosevelt',
        tags:"illustrations",
        url:"/work/routine-life",
        meta:"William Stein - Illustration - Lovve Reiniger - Routine Life",
        alt:"Lovve Reiniger - Routine Life",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/routine-life-min_td7ks8.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/routine-life_rsxfst.jpg"
        
    }
    ,
    {
        id:8,
        name:"A Night Of Nightmares",
        description:"When I was a kid, I usually hid myself behind the bed because of all the shadows I saw. My imagination scares me a lot, and I think it's the reason why I prefer, nowadays, to work and stay awake all night long. Btw, there is a reference to the awesome videogame #thebindingofisaac in this illustration",
        tags:"illustrations",
        url:"/work/night-of-nightmares",
        meta:"William Stein - Illustration - Lovve Reiniger - A Night Of Nightmares",
        alt:"Lovve Reiniger - A Night Of Nightmares",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647015094/night-of-nightmares-min_zr372l.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/night-of-nightmares_tsyqvu.jpg"
        
    }
    ,
    {
        id:9,
        name:"Prison Of Sorrow",
        description:'"It is so, when you have experienced sorrow ; you can’t escape it anymore."',
        tags:"illustrations",
        url:"/work/prison-of-sorrow",
        meta:"William Stein - Illustration - Lovve Reiniger - Prison Of Sorrow",
        alt:"Lovve Reiniger - Prison Of Sorrow",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014802/prison-of-sorrow-min_t6wyo0.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/prison-of-sorrow_etssda.jpg"
        
    }
    ,
    {
        id:10,
        name:"Behind The Shine",
        description:'"Focus on your goals, not your fear" - Roy Bennett',
        tags:"illustrations",
        url:"/work/behind-the-shine",
        meta:"William Stein - Illustration - Lovve Reiniger - Behind The Shine",
        alt:"Lovve Reiniger - Behind The Shine",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647011774/behind-the-shine-min_ocawot.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/behind-the-shine_xyg2tz.jpg"
        
    }
    ,
    {
        id:11,
        name:"Calipso",
        description:'A short demo of a video game I made, based on Limbo.',
        tags:"illustrations",
        url:"/work/calipso",
        meta:"William Stein - Video Game Demo - Calipso",
        miniature:"https://res.cloudinary.com/wstein88/video/upload/v1646582173/Calipso_shu0ah.mp4",
        image:"https://www.youtube.com/embed/62xsoHHPw0k"
        
    }
    ,
    {
        id:12,
        name:"The Warrior",
        description:'"A hero is someone who has given his/her life to something bigger than oneself" - Joseph Campbell',
        tags:"illustrations",
        url:"/work/the-warrior",
        meta:"William Stein - Illustration - Lovve Reiniger - The Warrior",
        alt:"Lovve Reiniger - The Warrior",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647014803/warrior-min_xernqd.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010234/warrior_aakbrg.jpg"
        
    }
    ,
    {
        id:13,
        name:"Frame By Frame",
        description:'Testing the frame-by-frame animation of a little cutie squirrel jumping from a tree, from right to left, on a sunny sunday.',
        tags:"illustrations",
        url:"/work/frame-by-frame",
        meta:"William Stein - Illustration - Lovve Reiniger - Frame By Frame",
        alt:"Lovve Reiniger - Frame By Frame",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647012558/frame-by-frame-min_il5kkc.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647010233/frame-by-frame_ngwhib.jpg"
        
    },
    {
        id:14,
        name:"WStein",
        description:'Business Cards with QR code integration for my own purpose.',
        tags:"branding",
        url:"/work/wstein",
        meta:"William Stein - Branding - WS / WStein - Business Cards",
        alt:"Branding - Business Cards for WS company",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647018854/ws-min_eihwry.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647018978/ws_ibkehl.jpg"
        
    },
    {
        id:15,
        name:"Pilates Time",
        description:'Large Signboard for a professional pilates center that can be seen for across the 4th exit of the E411 autoroute.',
        tags:"branding",
        url:"/work/pilates-time",
        meta:"William Stein - Branding - Pilates Time - Signboard",
        alt:"Branding - Large signboard for the pilates center Pilates Time",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647018426/pilates-min_zeruyn.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1646609162/pilates-time-signboard_nrx4o9.png"
        
    },
    {
        id:16,
        name:"Poseco",
        description:'Business Cards for the nonprofit organization Poseco (Positive Economy).',
        tags:"branding",
        url:"/work/poseco",
        meta:"William Stein - Branding - Poseco - Business Cards",
        alt:"Branding - Business Cards for the nonprofit organization POSECO",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1646645905/poseco-min_bhtrxj.png",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647019239/poseco_g0bs37.jpg"
        
    },
    {
        id:17,
        name:"SMB",
        description:"Logo of my ex-company. 5118 or SMB for Stein Media Business (there is a hidden message in this acronym).",
        tags:"branding",
        url:"/work/smb-5118",
        meta:"William Stein - Branding - SMB / 5118 - Logo",
        alt:"Branding - Logo for the SMB / 5118 company",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647017972/SMB-5118-min_xx5vp0.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647017967/SMB-5118_zxirkn.jpg"
        
    },
    {
        id:18,
        name:"Obscuras",
        description:'Cover for the new book of C.F.J. Dubuisson called "Obscuras".',
        tags:"branding",
        url:"/work/obscuras",
        meta:"William Stein - Branding - Cover Book - Obscuras by CFJ Dubuisson",
        alt:"Branding - Cover Book for CFJ Dubuisson",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647191870/obscuras-min_fx4yrg.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647191870/obscuras_cbum0w.jpg"
        
    },
    {
        id:19,
        name:"Birthday",
        description:"Contribution to a triptych posters for the 60th birthday of the patriarch.",
        tags:"events",
        url:"/work/birthday",
        meta:"William Stein - Branding - Poster - Birthday",
        alt:"Branding - Triptych Poster for a Birthday",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647016458/birthday-min_qe0v0u.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647016499/birthday_q0tdy1.jpg"
        
    },
    
    {
        id:20,
        name:"Carrefour",
        description:"Collab with Carrefour for the Bucolic Brussels event (local food market stands).",
        tags:"events",
        url:"/work/carrefour",
        meta:"William Stein - Branding - Panels - Carrefour",
        alt:"Branding - Panels for Local Food Stands - Carrefour",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647189637/carrefour-min_boaoaf.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647189637/carrefour_bsdsoq.png"
        
    },
    {
        id:21,
        name:"Bachelor Party",
        description:"T-shirts prints for a friend's bachelor party.",
        tags:"events",
        url:"/work/bachelor-party",
        meta:"William Stein - Branding - T-shirt - Bachelor Party",
        alt:"Branding - T-shirts for a Bachelor Party",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647017193/bachelor-party-min_ondb27.jpg",
        image:"https://res.cloudinary.com/wstein88/image/upload/v1647017155/bachelor-party_moqjmr.jpg"
        
    },
    {
        id:22,
        name:"GRAPPE",
        description:"Wordpress blog for the nonprofit organization GRAPPE (Groupe de Réflexion et d'Action Pour une Politique Écologique)",
        tags:"web",
        url:"/work/grappe",
        meta:"William Stein - Web Development - GRAPPE Belgique",
        alt:"Web Development - Website for the nonprofit organization GRAPPE",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647546626/grappe-min_go8pxc.png",
        image:"https://www.grappebelgique.org"
        
    },
    {
        id:23,
        name:"Lovve Reiniger",
        description:"Creative One-Page Website for my silhouettes art",
        tags:"web",
        url:"/work/lovve-reiniger",
        meta:"William Stein - Web Development - Lovve Reiniger",
        alt:"Web Development - Website for my illustrations under the name of Lovve Reiniger",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647596502/lovve-reiniger-min_yv5pwz.png",
        image:"https://www.lovvereiniger.com"
        
    },
    {
        id:24,
        name:"DAMMM",
        description:"One-Page Website for my Youtube videos project: sharing asian movies with music",
        tags:"web",
        url:"/work/dammm",
        meta:"William Stein - Web Development - DAMMM - Discover Asian Movies and Minimal Music",
        alt:"Web Development - Website for my Youtube videos project called 'Dammm'",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647555291/dammm-min_mgdwlo.jpg",
        image:"https://www.dammm.be"
        
    },
    {
        id:25,
        name:"1day1cover",
        description:"Wordpress blog for my album covers project: sharing a creative cover and a new artist every single day",
        tags:"web",
        url:"/work/1day1cover",
        meta:"William Stein - Web Development - 1day1cover - Discover a new cover and a new artist every day",
        alt:"Web Development - Website for my album cover project called 1day1cover",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1648282206/1day1cover-min_emogp7.jpg",
        image:"https://www.1day1cover.com"
        
    },
    {
        id:26,
        name:"Pilates Time",
        description:"Website for Pilates Time, a professional pilates center in Bierges ",
        tags:"web",
        url:"/work/pilates-time-website",
        meta:"William Stein - Web Development - Pilates Time - Professional pilates center in Bierges",
        alt:"Web Development - Website for the pilates center called Pilates Time",
        miniature:"https://res.cloudinary.com/wstein88/image/upload/v1647595957/pilates-time-website-min_vrecjv.jpg",
        image:"https://www.pilatestime.be/fr/"
        
    }
    
] 