export const lightTheme = {
  body: "#fff",
  text: "#000",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "255,255,255",
  textRgba: "0,0,0",
  toggleBorder: "#000",
  background: "#fff",
};

export const darkTheme = {
  body: "#000",
  text: "#fff",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "0,0,0",
  textRgba: "255,255,255",
  toggleBorder: "#fff",
  background: "#000",
};

export const breakpoints = {
  sm: 20,//em
  md: 30,
  lg: 45,
  xl: 60,
  xxl:75,
}

export const mediaQueries = key => {
  return style => `@media (max-width: ${key}em) { ${style} }`
}