import { NavLink } from "react-router-dom";
import styled from "styled-components";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const GoBack = styled.button`
position: relative;

background-color: transparent;
border: none;

width: 8rem;
height: auto;
cursor: pointer;
z-index: 3;
`;

const NotFoundDarkMode = () => {
  const container = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      name: "notFoundAnimationDarkMode",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/svgs/notFoundAnimDarkMode.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <GoBack>
        <NavLink to="/">
          <div
            className="container"
            ref={container}
          ></div>
        </NavLink>
      </GoBack>
    </>
  );
};

export default NotFoundDarkMode;