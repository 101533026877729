import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #fff;
    width: fit-content;
    margin: 7.5rem auto 4rem auto;
    padding: 0.4rem 0.4rem;
    border-radius: 4px;
`

const Form = styled.form`
    display: flex;
    align-items: center;
    background-color: #fff;
`

const SearchHolder = styled.input`
    background-color: #fff;
    color: #000;
    outline: none;
    border: none;
`

const Clear = styled.span`
    padding-right: 0.5rem;
    cursor: pointer;
`

const Go = styled.button`
    outline: none;
    border: none;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
`

const SearchBar = ({formSubmit, handleSearchKey, value, clearSearch}) => {
  return (
    <Container>
        <Form onSubmit={formSubmit}>
            <SearchHolder
                type='text'
                onChange={handleSearchKey}
                placeholder='Search by category'
                value={value}
            />
            {value && <Clear onClick={clearSearch}>X</Clear>}
            <Go>Go</Go>
        </Form>
    </Container>
  )
}

export default SearchBar