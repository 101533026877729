import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { ArrowRight, ArrowLeft } from "../assets/svgs/AllSvgs";
import { Works } from "../assets/datas/WorkDatas";

import GoBackButton from "../subcomponents/GoBack";
import GoBackButtonDarkMode from "../subcomponents/GoBackDarkMode";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import GlobalStyle from "../styles/globalStyle";
import InitialTransition from '../subcomponents/InitialTransition';
import InitialTransitionDarkMode from '../subcomponents/InitialTransitionDarkMode';



const MainContainer = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  position: relative;
  padding-top: 5rem;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Karla", sans-serif;
    font-weight: 500;
  }
`;

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

`;

const Image = styled.img`
  width: auto;
  max-width: 1200px;
  max-height: 600px;
  height: auto;
  padding: 1rem 0;

  @media (max-width: 767px) {
  width: auto;
  max-width: 400px;
  height: auto;
}
`;

const Title = styled.h1`
  color: ${(props) => props.theme.text};
  text-align: center;
`;

const Description = styled.h3`
  color: ${(props) => props.theme.text};
  text-align: center;
  max-width: 1200px;
  padding: 1rem 0;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  padding: 2rem;
`;
const OnArrow = styled(NavLink)`
  cursor: pointer;
`;

const Website = styled.object`
  width: 80vw;
  height: 100vh;
  margin: 1rem auto;
`

const FullPageCardComponent = (props) => {

  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  const { id, name, image, description, url, alt } = props.work;

  const firstEl = Works[0].url;
  const lastEl = Works[Works.length - 1].url;

  const nextId = id + 1;
  const previousId = id - 1;

  let result = Works.filter((workItem) => workItem.id === nextId);
  let result2 = Works.filter((workItem) => workItem.id === previousId);

  let nextUrl = "";
  let prevUrl = "";

  if ((url !== firstEl) & (url !== lastEl)) {
    nextUrl = result[0].url;
    prevUrl = result2[0].url;
  }
  if (url === firstEl) {
    nextUrl = result[0].url;
    prevUrl = lastEl;
  }
  if (url === lastEl) {
    nextUrl = firstEl;
    prevUrl = result2[0].url;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={themeMode}>
      {theme === "light" ?
    <InitialTransition/> : <InitialTransitionDarkMode/>}
    <GlobalStyle />
    {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
    {theme === "light" ? <NavLink to="/work/"><GoBackButton /></NavLink> : <NavLink to="/work/" ><GoBackButtonDarkMode/></NavLink>} 
    <MainContainer >
      <Container>
        <Title>{name}</Title>
        {id === 11 && <iframe width="1268" height="713" src={image} title="Calipso" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
        {id === 22 && <Website data={image} title="GRAPPE Website" type="text/html"></Website>}
        {id === 23 && <Website data={image} title="Lovve Reiniger Website" type="text/html"></Website>}
        {id === 24 && <Website data={image} title="DAMMM Website" type="text/html"></Website>}
        {id === 25 && <Website data={image} title="1day1cover Website" type="text/html"></Website>}
        {id === 26 && <Website data={image} title="1day1cover Website" type="text/html"></Website>}
        {id !==11 && id !==22 && id!==23 && id!==24 && id!==25 && id!==26 && <Image src={image} alt={alt}/>}
        <Description>{description}</Description>
      </Container>
      <ArrowContainer>
        <OnArrow to={prevUrl} >
        {theme === "light" ? 
        <ArrowLeft
            width={25}
            height={25}
            fill="black"
          /> : 
          <ArrowLeft
            width={25}
            height={25}
            fill="white"
          />
          }
        </OnArrow>
        <OnArrow to={nextUrl}>
        {theme === "light" ?
          <ArrowRight
            width={25}
            height={25}
            fill="black"
          />
          :
          <ArrowRight
            width={25}
            height={25}
            fill="white"
          />}
        </OnArrow>
      </ArrowContainer>
    </MainContainer>
    </ThemeProvider>
  );
};

export default FullPageCardComponent;
