import styled from "styled-components";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const SwitchMode = styled.button`
    position: absolute;
    top: 1rem;
    left: calc(1rem + 2vw);
    text-decoration: none;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: none;
    width: 2.5rem;
    height: auto;
`;

const Toggle = ({ theme, toggleTheme }) => {
  const container = useRef(null);
  function playAnim() {
    lottie.play("switchAnimation");
  }

  function stopAnim() {
    lottie.stop("switchAnimation");
  }
  useEffect(() => {
    lottie.loadAnimation({
      name: "switchAnimation",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: require("../assets/svgs/switch-animation2.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <SwitchMode onClick={toggleTheme}>
      <div
            className="container"
            ref={container}
            onMouseOver={playAnim}
            onMouseLeave={stopAnim}
          ></div>
      </SwitchMode>
    </>
  );
};

export default Toggle;