import { useState } from "react";
import styled from "styled-components";
import GlobalStyle from "../styles/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import HomeButtonDarkMode from "../subcomponents/HomeButtonDarkMode";
import SocialIcons from "../subcomponents/SocialIcons";
import SocialIconsDarkMode from "../subcomponents/SocialIconsDarkMode";
import HomeButton from "../subcomponents/HomeButton";
import { blogList } from "../assets/datas/BlogDatas";
import BlogList from "../components/BlogList";
import { Helmet } from 'react-helmet-async';



import SearchBar from "../components/SearchBar";
import EmptySearch from "../subcomponents/EmptySearch";


const MainContainer = styled.div`
  position: relative;
  width: 100vw;
  height: auto;
  background-color: ${({ theme }) => theme.background};
`;

const TitleSEO = styled.h1`
  color: ${props => props.theme.body};
  position: absolute;
  bottom:0;
  right:0;
  font-size: 0.1rem;
  cursor: default;
`;

const Blog = () => {

  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

// blogs setBlogs
  const [blogs, setBlogs] = useState(blogList)

  const [searchKey,setSearchKey] = useState('');

  const handleSearchSubmit=event=>{
    event.preventDefault()
    handleSearchResults()
  }

  const handleSearchResults=()=>{
    const allBlogs = blogList;
    const filteredBlogs = allBlogs.filter((blog) => 
    blog.category.toLowerCase().includes(searchKey.toLowerCase().trim())
  )
    setBlogs(filteredBlogs)
}

const handleClearSearch=()=>{
  setBlogs(blogList)
  setSearchKey('')
}

  return (
    <>
    <Helmet>
      <title>Blog</title>
      <meta name="description" content="William Stein - Blog: Design, Web, Animation, Passion, Travel, Cinema, IAD"/>
      <link rel="canonical" href="/blog" />
    </Helmet>
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      <MainContainer>
      <TitleSEO>Blog: IAD, Cinema, Travel, Animation, Design and Web Development</TitleSEO>
      {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      {theme === "light" ? <HomeButton /> : <HomeButtonDarkMode />}
      {theme === "light" ? <SocialIcons /> : <SocialIconsDarkMode />}
      
      {!blogs.length ? <EmptySearch/> : <BlogList blogs={blogs}/>}
      

        <SearchBar 
          value={searchKey}
          clearSearch={handleClearSearch}
          formSubmit={handleSearchSubmit}
          handleSearchKey={(e)=>setSearchKey(e.target.value)}
        />

      </MainContainer>
    </ThemeProvider>
    </>
  );
};

export default Blog;
