import {useState, useEffect} from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { WsProfileBlack } from "../assets/svgs/AllSvgs";
import { mediaQueries } from "./Themes";

const Box = styled(motion.div)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 65vw;
  height: 55vh;
  display: flex;

  background: linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      bottom,
    linear-gradient(
        to right,
        ${(props) => props.theme.body} 50%,
        ${(props) => props.theme.text} 50%
      )
      top;
  background-repeat: no-repeat;
  background-size: 100% 2px;

  border-left: 2px solid ${(props) => props.theme.body};
  border-right: 2px solid ${(props) => props.theme.text};

  z-index: 1;

  ${mediaQueries(1200)`
  width: 65vw;
`};

${mediaQueries(60)`
  width: 70vw;
`};

${mediaQueries(50)`
  width: 50vw;
  background-size: 100% 2px;

  flex-direction:column;
  justify-content:space-between;

`};

${mediaQueries(40)`
  width: 60vw;
  

`};

${mediaQueries(30)`
  width: 70vw;
  

`};
${mediaQueries(20)`
  width: 60vw;
  

`};

@media only screen and (max-width: 50em) {
  background: none;
  border: none;
  border-top: 2px solid ${(props) => props.theme.body};
  border-bottom: 2px solid ${(props) => props.theme.text};
  background-image: linear-gradient(
      ${(props) => props.theme.body} 50%,
      ${(props) => props.theme.text} 50%
    ),
    linear-gradient(
      ${(props) => props.theme.body} 50%,
      ${(props) => props.theme.text} 50%
    );
  background-size: 2px 100%;
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat;
}

//height:55vh;
`;

const SubBox = styled(motion.div)`
  width: 50%;
  position: relative;
  display: flex;
  border: none;
  .pic {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 70%;
    height: auto;
  }
  ${mediaQueries(50)`
      width: 100%;
    height: 50%;
      .pic {
    
    width: 50%;
    
  }

  `};

  ${mediaQueries(40)`
  
      .pic {
    
    width: 60%;
    
  }

  `};

  ${mediaQueries(30)`
     

      .pic {
    
    width: 70%;
    
  }

  `};
  ${mediaQueries(20)`
     

     .pic {
   
   width: 60%;
   
 }

 `};
`;

const Text = styled.div`
  font-size: calc(1em + 1.5vw);
  color: ${(props) => props.theme.body};
  padding: 2rem;
  text-align: end;

  display: flex;
  flex-flow: column wrap;
  justify-content: space-evenly;

  & > *:last-child {
    font-weight: 300;
    ${mediaQueries(40)`
    font-size: calc(0.5rem + 1vw);
    text-align: center;

`};
}

${mediaQueries(40)`
    font-size: calc(1rem + 1.5vw);
    text-align: center;


`};
${mediaQueries(20)`
     padding: 1rem;
     text-align: center;



`};
`;

const Intro = () => {
  const [height, setHeight] = useState("55vh");

  useEffect(() => {
    if (window.matchMedia("(max-width: 50em)").matches) {
      setHeight("70vh");
    }
    if (window.matchMedia("(max-width: 20em)").matches) {
      setHeight("60vh");
    }
  }, []);
  return (
    <Box
      initial={{ height: 0 }}
      animate={{ height: height }}
      transition={{ type: "spring", duration: 2, delay: 1 }}
    >
      <SubBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
      >
        <Text>
          <h1>Yop,</h1>
          <h3>I'm William Stein.</h3>
          <h6>
            I'm a graphic designer and a web developer. It means I have a
            great sensibility for art, but I also like to drink my coffee in
            front of a bunch of codes.
          </h6>
        </Text>
      </SubBox>
      <SubBox
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 2 }}
      >
        <WsProfileBlack className="pic"/>
      </SubBox>
    </Box>
  );
};

export default Intro;
