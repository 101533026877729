import {Works} from '../../assets/datas/WorkDatas';
import FullPageCardComponent from '../../subcomponents/FullPageCardComponent';
import { Helmet } from 'react-helmet-async';



const TheKiller = () => {
    

    return (
        <>
                {       
                    Works
                    .filter(work => work.id === 1)
                    .map(work => {
                    return (<div key={work.id} >
                        <Helmet>
                            <title>{work.name}</title>
                            <meta name="description" content={work.meta}/>
                            <link rel="canonical" href={work.url} />
                        </Helmet>
                        <FullPageCardComponent work={work} />
                        </div>)
                })
            }       
            </>
    )   
}

export default TheKiller;