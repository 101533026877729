import React from "react";
import styled from "styled-components";
import {
  InstagramIcon,
  LinkedinIcon,
  YoutubeIcon,
  DeviantIcon,
  InstagramSquareIcon,
} from "../assets/svgs/AllSvgs";
import { darkTheme } from "../components/Themes";

const Icons = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 2rem;

  z-index: 3;

  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Line = styled.span`
  width: 3px;
  height: 4rem;
  background-color: ${(props) =>
    props.color === "darkIntro" ? darkTheme.text : darkTheme.body};
`;

const SocialIcons = (props) => {
  return (
    <Icons>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/1day1cover/"
        >
          <InstagramIcon
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/1day1cover/"
        >
          <InstagramSquareIcon
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.deviantart.com/lovvereiniger"
        >
          <DeviantIcon
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/william-stein-57865758"
        >
          <LinkedinIcon
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCiwHSc1YY9WNi3fLofzXTpw"
        >
          <YoutubeIcon
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <Line color={props.theme} />
    </Icons>
  );
};

export default SocialIcons;
