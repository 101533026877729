import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Card = styled(Link)`
  width: calc(10rem + 15vw);
  text-decoration: none;
  height: 20rem;
  padding: 1rem;
  color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => props.theme.text};
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  flex-flow: column nowrap;
  z-index: 5;

  &:hover {
    color: ${(props) => props.theme.body};
    background-color: ${(props) => props.theme.text};
    transition: all 0.9s ease;
  }
  @media (max-width: 767px){
    width: 90%;
    margin: 0 auto;
  }
`;
//backdrop-filter: blur(2px); en cas d'un background-image

const Image = styled.div`
  background-image: ${(props) => `url(${props.img})`};
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  background-position: center center;

  ${Card}:hover & {
    border: 1px solid ${(props) => props.theme.body};
  }
`;

const Title = styled.h2`
  color: inherit;
  font-size: 1.3rem;
  padding: 0.5rem 0;
  padding-top: 1rem;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  border-bottom: 1px solid ${(props) => props.theme.text};
  ${Card}:hover & {
    border-bottom: 1px solid ${(props) => props.theme.body};
  }
`;

const HashTags = styled.div`
  padding: 0.5rem 0;
  display: flex;
  flex-flow: row wrap;
`;

const Tag = styled.span`
  padding-right: 0.5rem;
`;

const Bottom = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const Date = styled.span`
  padding: 0.5rem 0;
`;

const Category = styled.p`
  padding: 0.5rem 0;
  font-style: italic;
`;

const BlogCard = ({
  blog: { name, date, imgSrc, alt, subTags, category, id, url},
}) => {
  return (
    <>
    <Card to={`/blog/${id}/${url}`}>
      <Image img={imgSrc[0]} alt={alt} />
      <Title>{name}</Title>
      <HashTags>
        {subTags.map((t, id) => {
          return <Tag key={id}>#{t}</Tag>;
        })}
      </HashTags>
      <Bottom>
        <Date>{date}</Date>
        <Category>{category}</Category>
      </Bottom>
    </Card>
    </>
  )
}

export default BlogCard;
