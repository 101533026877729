import styled from "styled-components";
import GlobalStyle from "../styles/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import HomeButton from "../subcomponents/HomeButton";
import HomeButtonDarkMode from "../subcomponents/HomeButtonDarkMode";
import NotFound from "../subcomponents/NotFound";
import NotFoundDarkMode from "../subcomponents/NotFoundDarkMode";


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  color: ${(props) => props.theme.text};
`

const Text = styled.p`
  color: ${(props) => props.theme.text};
`

const Error404 = () => {
    const [theme, themeToggler] = useDarkMode();

    const themeMode = theme === "light" ? lightTheme : darkTheme;
    return (
        <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> : <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      {theme === "light" ? <HomeButton /> : <HomeButtonDarkMode />}
      <Container>
      {theme === "light" ? <NotFound /> : <NotFoundDarkMode />}
      <Text>The page doesn't exist</Text>
    </Container>
      </ThemeProvider>
    )
}

export default Error404;