import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";


const Card = styled(NavLink)`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0 0.7rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
  border: none;
  background-color: ${(props) => props.theme.body};
  text-decoration: none;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 1;
  ${Card}:hover & {
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.9s ease;
  }
`;

const Video = styled.video`
  width: 101%;
  height: auto;
  object-fit: cover;
  opacity: 1;
  ${Card}:hover & {
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.9s ease;
  }
`;

const Title = styled.h2`
  color: ${(props) => props.theme.text};
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  position: relative;
  top: -50%;

  opacity: 0;
  ${Card}:hover & {
    opacity: 1;
    transition: all 0.9s ease;
  }
`;

const WorkCard = (props) => {
  const { id, name, miniature, url } = props.work;

  return (

    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Card to={url} key={id}>
      {id === 11 ? <Video autoPlay loop muted playsinline type="video/mp4" src={miniature} /> : <Image src={miniature} alt={name} /> }
      
        <Title>{name}</Title>
      </Card>
    </motion.div>
  );
};

export default WorkCard;
