import { NavLink } from "react-router-dom";
import styled from "styled-components";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const Power = styled.button`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: transparent;
  border: none;

  width: 8rem;
  height: auto;
  cursor: pointer;
  z-index: 3;
`;

const HomeButtonDarkMode = () => {
  const container = useRef(null);
  function playAnim() {
    lottie.play("parrotAnimationDarkMode");
  }

  function stopAnim() {
    lottie.stop("parrotAnimationDarkMode");
  }
  useEffect(() => {
    lottie.loadAnimation({
      name: "parrotAnimationDarkMode",
      container: container.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: require("../assets/svgs/parrotAnimDarkMode.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <Power>
        <NavLink to="/">
          <div
            className="container"
            ref={container}
            onMouseOver={playAnim}
            onMouseLeave={stopAnim}
          ></div>
        </NavLink>
      </Power>
    </>
  );
};

export default HomeButtonDarkMode;
