import styled from "styled-components";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const GoBack = styled.button`
    position: absolute;
    top: 1rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: transparent;
    border: none;
    width: 2rem;
    height: auto;
    cursor: pointer;
    z-index: 3;
`;

const GoBackButtonDarkMode = () => {
  const container = useRef(null);
  function playAnim() {
    lottie.play("goBackDarkMode");
  }

  function stopAnim() {
    lottie.stop("goBackDarkMode");
  }
  useEffect(() => {
    lottie.loadAnimation({
      name: "goBackDarkMode",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData: require("../assets/svgs/goBack2DarkMode.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <GoBack>
          <div
            className="container"
            ref={container}
            onMouseOver={playAnim}
            onMouseLeave={stopAnim}
          ></div>
      </GoBack>
    </>
  );
};

export default GoBackButtonDarkMode;
