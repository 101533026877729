import "./styles/App.css";
import { Route, Routes, useLocation } from "react-router-dom";

// PAGES
import Main from "./pages/Main";
import Work from "./pages/Work";
import Blog from "./pages/Blog";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";
import TheMirror from "./pages/subpages/TheMirror";
import Duality from "./pages/subpages/Duality";
import MeMyselfAndI from "./pages/subpages/MeMyselfAndI";
import NaturalSelection from "./pages/subpages/NaturalSelection";
import NightOfNightmares from "./pages/subpages/NightOfNightmares";
import PrisonOfSorrow from "./pages/subpages/PrisonOfSorrow";
import TheTrap from "./pages/subpages/TheTrap";
import TheKiller from "./pages/subpages/TheKiller";
import RoutineLife from "./pages/subpages/RoutineLife";
import BehindTheShine from "./pages/subpages/BehindTheShine";
import Calipso from "./pages/subpages/Calipso";
import TheWarrior from "./pages/subpages/TheWarrior";
import FrameByFrame from "./pages/subpages/FrameByFrame";
import BlogCardPage from "./pages/BlogCardPage";
import WSteinBC from "./pages/subpages/WSteinBC";
import PilatesTime from "./pages/subpages/PilatesTime";
import Poseco from "./pages/subpages/Poseco";
import BachelorParty from "./pages/subpages/BachelorParty";
import Birthday from "./pages/subpages/Birthday";
import SMB from "./pages/subpages/SMB";
import Carrefour from "./pages/subpages/Carrefour";
import Obscuras from "./pages/subpages/Obscuras";
import Grappe from "./pages/subpages/Grappe";
import LovveReiniger from "./pages/subpages/LovveReiniger";
import Dammm from "./pages/subpages/Dammm";
import Error404 from './pages/Error404';
import DayCover from "./pages/subpages/DayCover";
import PilatesTimeWebsite from "./pages/subpages/PilatesTimeWebsite";

//<Route path="*" element={<NotFound/>} />

function App() {
  const location = useLocation();

  return (
    <>
      
        <Routes location={location} key={location.pathname}>
          <Route exact path="/" element={<Main />} />
          <Route path="/projects" element={<Projects/>} />
          <Route path="/blog/" element={<Blog />} />
          <Route path="/blog/:id/:url/" element={<BlogCardPage />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/work" element={<Work />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/work/the-killer" element={<TheKiller />} />
          <Route path="/work/the-trap" element={<TheTrap />} />
          <Route path="/work/me-myself-and-i" element={<MeMyselfAndI />} />
          <Route path="/work/natural-selection" element={<NaturalSelection />} />
          <Route path="/work/the-mirror" element={<TheMirror />} />
          <Route path="/work/duality" element={<Duality />} />
          <Route path="/work/routine-life" element={<RoutineLife />} />
          <Route path="/work/night-of-nightmares" element={<NightOfNightmares />} />
          <Route path="/work/prison-of-sorrow" element={<PrisonOfSorrow />} />
          <Route path="/work/behind-the-shine" element={<BehindTheShine />} />
          <Route path="/work/calipso" element={<Calipso />} />
          <Route path="/work/the-warrior" element={<TheWarrior />} />
          <Route path="/work/frame-by-frame" element={<FrameByFrame />} />
          <Route path="/work/wstein" element={<WSteinBC />} />
          <Route path="/work/pilates-time" element={<PilatesTime />} />
          <Route path="/work/poseco" element={<Poseco />} />
          <Route path="/work/bachelor-party" element={<BachelorParty />} />
          <Route path="/work/birthday" element={<Birthday />} />
          <Route path="/work/smb-5118" element={<SMB />} />
          <Route path="/work/carrefour" element={<Carrefour />} />
          <Route path="/work/obscuras" element={<Obscuras />} />
          <Route path="/work/grappe" element={<Grappe />} />
          <Route path="/work/lovve-reiniger" element={<LovveReiniger />} />
          <Route path="/work/dammm" element={<Dammm />} />
          <Route path="/work/1day1cover" element={<DayCover />} />
          <Route path="/work/pilates-time-website" element={<PilatesTimeWebsite />} />
        </Routes>

    </>
  );
}

export default App;
