import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  color: ${(props) => props.theme.text};
`

const Text = styled.p`
  color: ${(props) => props.theme.text};
`

const EmptySearch = () => {
  return (
    <Container>
    <Text>Don't try to use the search bar for now, there isn't enough content</Text>
    </Container>
  )
}

export default EmptySearch