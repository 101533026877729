import React from "react";
import styled from "styled-components";
import "../styles/App.css";

const Button = styled.button`
  margin: 0 10px;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  border: none;
  outline: none;
  background-color: transparent;
  color: ${(props) => props.theme.text};
  cursor: pointer;

  &:hover {
    font-weight: bold;
  }
`;

const Filter = ({ name, handleSetTag, tagActive }) => {
  return (
    <Button
      className={`${tagActive ? "active" : null}`}
      onClick={() => handleSetTag(name)}
    >
      {name}
    </Button>
  );
};

export default Filter;
