import React from "react";
import styled from "styled-components";
import {
  InstagramIconDarkMode,
  LinkedinIconDarkMode,
  YoutubeIconDarkMode,
  DeviantIconDarkMode,
  InstagramSquareIconDarkMode,
} from "../assets/svgs/AllSvgs";
import { darkTheme } from "../components/Themes";

const Icons = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 2rem;

  z-index: 3;

  & > *:not(:last-child) {
    margin: 0.5rem 0;
  }
  @media (max-width: 767px) {
    display: none;
  }
`;

const Line = styled.span`
  width: 3px;
  height: 4rem;
  background-color: white;
`;

const SocialIcons = (props) => {
  return (
    <Icons>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/1day1cover/"
        >
          <InstagramIconDarkMode
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/1day1cover/"
        >
          <InstagramSquareIconDarkMode
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.deviantart.com/lovvereiniger"
        >
          <DeviantIconDarkMode
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/in/william-stein-57865758"
        >
          <LinkedinIconDarkMode
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <div>
        <a
          style={{ color: "inherit" }}
          target="_blank"
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCiwHSc1YY9WNi3fLofzXTpw"
        >
          <YoutubeIconDarkMode
            width={25}
            height={25}
            fill={props.theme === "darkIntro" ? darkTheme.text : darkTheme.body}
          />
        </a>
      </div>
      <Line />
    </Icons>
  );
};

export default SocialIcons;
