import { NavLink } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import HomeButton from "../subcomponents/HomeButton";
import HomeButtonDarkMode from "../subcomponents/HomeButtonDarkMode";
import SocialIcons from "../subcomponents/SocialIcons";
import SocialIconsDarkMode from "../subcomponents/SocialIconsDarkMode";
import LogoAnimation from "../subcomponents/LogoAnimation";
import LogoAnimationDarkMode from "../subcomponents/LogoAnimationDarkMode";
import Intro from "../components/Intro";
import IntroDarkMode from "../components/IntroDarkMode";

//import { useState } from 'react';
import GlobalStyle from "../styles/globalStyle";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme, mediaQueries } from "../components/Themes";
import { useDarkMode } from "../components/useDarkMode";
import Toggle from "../components/SwitchButton";
import ToggleDarkMode from "../components/SwitchButtonDarkMode";
import { Helmet } from 'react-helmet-async';

const MainContainer = styled.div`
  background-color: ${(props) => props.theme.body};
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  position: relative;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Karla", sans-serif;
    font-weight: 500;
  }
  h2 {
    ${mediaQueries(40)`
      font-size:1.2em;

  `};

    ${mediaQueries(30)`
      font-size:1em;

  `};
  }
`;

const Container = styled.div`
  padding: 2rem;
`;

const Contact = styled.div`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 2rem;
  right: calc(1rem + 2vw);
  text-decoration: none;
  z-index: 1;
  @media only screen and (max-width: 50em) {
    color: ${(props) => (props.click ? props.theme.body : props.theme.text)};
  }
`;

const ContactDARKMODE = styled.div`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 2rem;
  right: calc(1rem + 2vw);
  text-decoration: none;
  z-index: 1;
  @media only screen and (max-width: 50em) {
    color: ${(props) => props.theme.text};
  }
`;



const BLOG = styled(NavLink)`
  color: ${(props) => props.theme.text};

  text-decoration: none;
  z-index: 1;
`;

const WORK = styled(NavLink)`
  color: ${(props) => (props.click ? props.theme.body : props.theme.text)};

  text-decoration: none;
  z-index: 1;
  @media only screen and (max-width: 50em) {
    color: ${(props) => (props.click ? props.theme.text : props.theme.text)};
  }
`;

const WORKDARKMODE = styled(NavLink)`
  color: white;

  text-decoration: none;
  z-index: 1;
`;

const BottomBar = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
`;

const ABOUT = styled(NavLink)`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 50%;
  right: 2vw;
  transform: rotate(90deg) translate(-50%, -50%);

  text-decoration: none;
  z-index: 1;
  @media only screen and (max-width: 50em) {
    color: ${(props) => (props.click ? props.theme.body : props.theme.text)};
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }
`;

const ABOUTDARKMODE = styled(NavLink)`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 50%;
  right: 2vw;
  transform: rotate(90deg) translate(-50%, -50%);

  text-decoration: none;
  z-index: 1;
  @media only screen and (max-width: 50em) {
    color: ${(props) => props.theme.text};
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }
`;

const SKILLS = styled(NavLink)`
  color: ${(props) => (props.click ? props.theme.body : props.theme.text)};
  position: absolute;
  top: 50%;
  left: calc(1rem + 2vw);
  transform: translate(-50%, -50%) rotate(-90deg);
  text-decoration: none;
  z-index: 100;
  @media only screen and (max-width: 50em) {
    text-shadow: ${(props) => (props.click ? "0 0 4px #000" : "none")};
  }
`;

const SKILLSDARKMODE = styled(NavLink)`
  color: ${(props) => props.theme.text};
  position: absolute;
  top: 50%;
  left: calc(1rem + 2vw);
  transform: translate(-50%, -50%) rotate(-90deg);
  text-decoration: none;
  z-index: 1;
`;

const Center = styled.div`
  position: absolute;
  top: ${(props) => (props.click ? "85%" : "50%")};
  left: ${(props) => (props.click ? "92%" : "50%")};
  transform: translate(-50%, -50%);

  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;

  display: flex;
  flex-flow: column no-wrap;
  justify-content: center;
  align-items: center;

  transition: all 0.5s ease;

  @media only screen and (max-width: 50em) {
    top: ${(props) => (props.click ? "90%" : "50%")};
    left: ${(props) => (props.click ? "90%" : "50%")};
    width: ${(props) => (props.click ? "80px" : "200px")};
    height: ${(props) => (props.click ? "80px" : "200px%")};
  }
  @media only screen and (max-width: 30em) {
    width: ${(props) => (props.click ? "50px" : "200px")};
    height: ${(props) => (props.click ? "50px" : "200px")};
  }
`;

const DarkDiv = styled.div`
  position: absolute;
  top: 0;
  background-color: #000;
  bottom: 0;
  right: 50%;
  width: ${(props) => (props.click ? "50%" : "0%")};
  height: ${(props) => (props.click ? "100%" : "0%")};
  z-index: 0;
  transition: height 0.25s ease, width 0.5s ease 0.25s;

  ${(props) =>
    props.click
      ? mediaQueries(50)`
       height: 50%;
  right:0;
  
  width: 100%;
  transition: width 0.5s ease, height 1s ease 0.5s;

  `
      : mediaQueries(50)`
       height: 0;
  
  width: 0;
  `};
`;

const TitleSEO = styled.h1`
  color: ${props => props.theme.body};
  position: absolute;
  bottom:0;
  right:0;
  font-size: 0.1rem;
  cursor: default;
`;

const Main = () => {
  //Utilisation de 1 et 0 au lieu d'une valeur booléenne car rapport erreur dans la console
  let [click, setClick] = useState(0);

  const handleClick = () => setClick(click === 1 ? (click = 0) : (click = 1));
  const [theme, themeToggler] = useDarkMode();

  const themeMode = theme === "light" ? lightTheme : darkTheme;

  return (
    <>
    <Helmet>
      <title>WS - William Stein</title>
      <meta name="description" content="William Stein - Graphic Designer and Web Developer: Portfolio, projects and blog"/>
      <link rel="canonical" href="/" />
    </Helmet>
    <ThemeProvider theme={themeMode}>
      <GlobalStyle />
      
      {theme === "light" ? <Toggle theme={theme} toggleTheme={themeToggler} /> :  <ToggleDarkMode theme={theme} toggleTheme={themeToggler}/>}
      <MainContainer>
      <TitleSEO>WS - WStein - William Stein - Graphic Designer and Web Developer</TitleSEO>
        <DarkDiv click={click} />
        <Container>
       

          {theme === "light" ? (
            <SocialIcons theme={click ? "darkIntro" : "lightIntro"} />
          ) : (
            <SocialIconsDarkMode theme={click ? "darkIntro" : "lightIntro"} />
          )}
          <Center click={click} onClick={() => handleClick()}>
            {theme === "light" ? <LogoAnimation /> : <LogoAnimationDarkMode /> }
          </Center>

           {theme === "light" ? <HomeButton /> : <HomeButtonDarkMode/>}

           
          {theme === "light" ? (
          <Contact click={click}>
            <a style={{ color: "inherit" }} href="mailto:info@wstein.be">
              <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Let's chat
              </motion.h2>
            </a>
          </Contact>) : (
            <ContactDARKMODE click={click}>
            <a style={{ color: "inherit" }} href="mailto:info@wstein.be">
              <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Let's chat
              </motion.h2>
            </a>
          </ContactDARKMODE>)
          }

          {theme === "light" ? (
            <SKILLS to="/skills" click={click}>
              <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Skills.
              </motion.h2>
            </SKILLS>
          ) : (
            <SKILLSDARKMODE to="/skills">
              <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Skills.
              </motion.h2>
            </SKILLSDARKMODE>
          )}
          {theme === "light" ? (
          <ABOUT to="/projects" click={click}>
            <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              Projects.
            </motion.h2>
          </ABOUT>
          ) : (
            <ABOUTDARKMODE to="/projects" click={click}>
            <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              Projects.
            </motion.h2>
          </ABOUTDARKMODE>
          )}
          


          <BottomBar>
            {theme === "light" ? (
              <WORK to="/work" click={click}>
                <motion.h2
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Work.
                </motion.h2>
              </WORK>
            ) : (
              <WORKDARKMODE to="/work" click={click}>
                <motion.h2
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  Work.
                </motion.h2>
              </WORKDARKMODE>
            )}

            <BLOG to="/blog">
              <motion.h2 whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                Blog.
              </motion.h2>
            </BLOG>
          </BottomBar>
        </Container>
        {theme === "light" ? (
          click ? (
            <Intro click={click} />
          ) : null
        ) : click ? (
          <IntroDarkMode click={click} />
        ) : null}
      </MainContainer>
    </ThemeProvider>
    </>
  );
};

export default Main;
