import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const LogoAnimationDarkMode = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: "logoAnimDarkMode",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/svgs/logoAnim2DarkMode.json"),
    });

    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <div className="container" ref={container}></div>
    </>
  );
};

export default LogoAnimationDarkMode;
