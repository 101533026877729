import React from "react";
import BlogCard from "./BlogCard";
import styled from "styled-components";

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12rem 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(calc(10rem + 15vw), 1fr));
  grid-gap: calc(1rem + 2vw);
  @media (max-width: 767px){
    grid-template-columns: 100%;
}
`;

const BlogList = ({ blogs }) => {

  const blogReverse = [...blogs].reverse();
  return (
  <Center>
      <Grid>
    {blogReverse.map((blog) => (
      <BlogCard key={blog.id} blog={blog} />
    ))}
    </Grid>
  </Center>
)};

export default BlogList;
