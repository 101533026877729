import styled from "styled-components";
import lottie from "lottie-web";
import { useEffect, useRef } from "react";

const GoBack = styled.div`
  position: relative;

  background-color: transparent;
  border: none;

  width: 8rem;
  height: auto;
`;

const LrAnimDarkMode = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      name: "eyesAnimationDarkMode",
      container: container.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: require("../assets/svgs/eyesAnim.json"),
    });
    return () => {
      lottie.destroy();
    };
  }, []);

  return (
    <>
      <GoBack
            className="container"
            ref={container}
          >
      </GoBack>
    </>
  );
};

export default LrAnimDarkMode;